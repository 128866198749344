/*! normalize.css v8.0.1 | Licencia MIT | github.com/necolas/normalize.css */

/* Documento
   ========================================================================== */

/**
 * 1. Corregir la altura de línea en todos los navegadores.
 * 2. Prevenir ajustes de tamaño de fuente después de cambios de orientación en iOS.
 */

 * {
    margin: 0;
    box-sizing: border-box;
}

html {
    line-height: 1.15;
    /* 1 */
    -webkit-text-size-adjust: 100%;
    /* 2 */
}

/* Secciones
     ========================================================================== */

/**
   * Eliminar el margen en todos los navegadores.
   */

body {
    margin: 0;
}

/**
   * Renderizar el elemento `main` consistentemente en IE.
   */

main {
    display: block;
}

/**
   * Corregir el tamaño de fuente y margen en elementos `h1` dentro de contextos de `section` y
   * `article` en Chrome, Firefox y Safari.
   */

h1 {
    font-size: 2em;
    margin: 0;
}

/* Contenido agrupado
     ========================================================================== */

/**
   * 1. Añadir el box-sizing correcto en Firefox.
   * 2. Mostrar el desbordamiento en Edge e IE.
   */

hr {
    box-sizing: content-box;
    /* 1 */
    height: 0;
    /* 1 */
    overflow: visible;
    /* 2 */
}

/**
   * 1. Corregir la herencia y escalado del tamaño de fuente en todos los navegadores.
   * 2. Corregir el extraño tamaño de fuente `em` en todos los navegadores.
   */

pre {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */
}

/* Semántica de nivel de texto
     ========================================================================== */

/**
   * Eliminar el fondo gris en enlaces activos en IE 10.
   */

a {
    background-color: transparent;
}

/**
   * 1. Eliminar el borde inferior en Chrome 57-
   * 2. Añadir la decoración de texto correcta en Chrome, Edge, IE, Opera y Safari.
   */

abbr[title] {
    border-bottom: none;
    /* 1 */
    text-decoration: underline;
    /* 2 */
    text-decoration: underline dotted;
    /* 2 */
}

/**
   * Añadir el peso de fuente correcto en Chrome, Edge y Safari.
   */

b,
strong {
    font-weight: bolder;
}

/**
   * 1. Corregir la herencia y escalado del tamaño de fuente en todos los navegadores.
   * 2. Corregir el extraño tamaño de fuente `em` en todos los navegadores.
   */

code,
kbd,
samp {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */
}

/**
   * Añadir el tamaño de fuente correcto en todos los navegadores.
   */

small {
    font-size: 80%;
}

/**
   * Prevenir que los elementos `sub` y `sup` afecten la altura de línea en
   * todos los navegadores.
   */

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

/* Contenido incrustado
     ========================================================================== */

/**
   * Eliminar el borde en imágenes dentro de enlaces en IE 10.
   */

img {
    border-style: none;
}

/* Formularios
     ========================================================================== */

/**
   * 1. Cambiar los estilos de fuente en todos los navegadores.
   * 2. Eliminar el margen en Firefox y Safari.
   */

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    /* 1 */
    font-size: 100%;
    /* 1 */
    line-height: 1.15;
    /* 1 */
    margin: 0;
    /* 2 */
}

/**
   * Mostrar el desbordamiento en IE.
   * 1. Mostrar el desbordamiento en Edge.
   */

button,
input {
    /* 1 */
    overflow: visible;
}

/**
   * Eliminar la herencia de transformación de texto en Edge, Firefox e IE.
   * 1. Eliminar la herencia de transformación de texto en Firefox.
   */

button,
select {
    /* 1 */
    text-transform: none;
}

/**
   * Corregir la imposibilidad de estilizar tipos clicables en iOS y Safari.
   */

   button,
   [type="button"],
   [type="reset"],
   [type="submit"] {
       appearance: button; /* Establecer apariencia del botón para navegadores modernos */
       -webkit-appearance: button; /* Establecer apariencia del botón para navegadores WebKit como Chrome y Safari */
       padding: 5px 10px; /* Añadir un relleno para mejorar la apariencia */
       /* Agregar cualquier otra propiedad necesaria para personalizar el estilo del botón */
   }
   
/**
   * Eliminar el borde interior y el relleno en Firefox.
   */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/**
   * Restaurar los estilos de foco no establecidos por la regla anterior.
   */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

/**
   * Corregir el relleno en Firefox.
   */

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

/**
   * 1. Corregir el ajuste de texto en Edge e IE.
   * 2. Corregir la herencia de color de elementos `fieldset` en IE.
   * 3. Eliminar el rellado para que los desarrolladores no se confundan al anular
   *    elementos `fieldset` en todos los navegadores.
   */

legend {
    box-sizing: border-box;
    /* 1 */
    color: inherit;
    /* 2 */
    display: table;
    /* 1 */
    max-width: 100%;
    /* 1 */
    padding: 0;
    /* 3 */
    white-space: normal;
    /* 1 */
}

/**
   * Añadir la alineación vertical correcta en Chrome, Firefox y Opera.
   */

progress {
    vertical-align: baseline;
}

/**
   * Eliminar la barra de desplazamiento vertical predeterminada en IE 10+.
   */

textarea {
    overflow: auto;
}

/**
   * 1. Añadir el box-sizing correcto en IE 10.
   * 2. Eliminar el rellado en IE 10.
   */

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */
}

/**
   * Corregir el estilo de cursor de botones de incremento y decremento en Chrome.
   */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

/**
   * 1. Corregir la apariencia extraña en Chrome y Safari.
   * 2. Corregir el estilo de contorno en Safari.
   */

   [type="search"] {
    appearance: textfield; /* Establecer la apariencia del campo de búsqueda para navegadores modernos */
    -webkit-appearance: textfield; /* Establecer la apariencia del campo de búsqueda para navegadores WebKit como Chrome y Safari */
    outline-offset: -2px; /* Desplazar el contorno para mejorar la apariencia */
    /* Agregar cualquier otra propiedad necesaria para personalizar el estilo del campo de búsqueda */
}


/**
   * Eliminar el rellado interno en Chrome y Safari en macOS.
   */

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
   * 1. Corregir la imposibilidad de estilizar tipos clicables en iOS y Safari.
   * 2. Cambiar las propiedades de fuente a `inherit` en Safari.
   */

::-webkit-file-upload-button {
    -webkit-appearance: button;
    /* 1 */
    font: inherit;
    /* 2 */
}

/* Interactivo
     ========================================================================== */

/*
   * Añadir la visualización correcta en Edge, IE 10+ y Firefox.
   */

details {
    display: block;
}

/*
   * Añadir la visualización correcta en todos los navegadores.
   */

summary {
    display: list-item;
}

/* Misceláneo
     ========================================================================== */

/**
   * Añadir la visualización correcta en IE 10+.
   */

template {
    display: none;
}

/**
   * Añadir la visualización correcta en IE 10.
   */

[hidden] {
    display: none;
}
