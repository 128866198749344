  /* ------------------------ */
  /* Variables globales */
  /* ------------------------ */

  :root {
      --padding-container: 100px 0;
      --color-title: #001A49;
      --color-highlight: #0facbf;
      --font-family: 'Ergonomique Bold', sans-serif;
      /* Nueva fuente para toda la página */
      --backgroundImage-before: url('../img/pexels-castorly-stock-4114781.jpg')
  }

  /* ------------------------ */
  /* Estilos generales */
  /* ------------------------ */
  /* Establecer el tamaño de fuente base y otras propiedades globales */

  body {
      font-family: 'Ergonomique Bold', sans-serif;
      font-size: 16px;
      line-height: 1.6;
      margin: 0;
      padding: 0;
  }

  /* ------------------------ */
  /* Estilos del encabezado */
  /* ------------------------ */
  /* Estilos para el título del héroe y otros elementos relacionados */

  .hero__title {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 3rem;
  }

  .nav__title .highlight,
  .hero__title .highlight {
      color: #0facbf;
  }

  .nav__title img,
  .hero__title img {
      width: 40px;
      height: auto;
      margin-left: 5px;
  }

  /* ------------------------ */
  /* Estilos del contenedor principal */
  /* ------------------------ */
  /* Estilos para el contenedor principal que limita el ancho y proporciona espacio */

  .container {
      width: 90%;
      max-width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      padding: var(--padding-container);
  }

  /* Estilos para la sección del héroe con imagen de fondo y capa de color */

  .hero {
      width: 100%;
      height: 100vh;
      min-height: 600px;
      max-height: 800px;
      position: relative;
      display: grid;
      grid-template-rows: 100px 1fr;
      color: #fff;
  }

  .hero::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, #0000008c 0%, #0000008c 100%), --var(--backgroundImage-before, none);
      background-size: cover;
      clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 95%, 0 80%);
      z-index: -1;
  }

  /* ------------------------ */
  /* Estilos de navegación */
  /* ------------------------ */
  /* Estilos para la barra de navegación y enlaces de navegación */

  .nav {
      --padding-container: 0;
      height: 100%;
      display: flex;
      align-items: center;
  }

  .nav__title {
      font-weight: 300;
  }

  .nav__link {
      margin-left: auto;
      padding: 0;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      gap: 2em;
  }

  .nav__items {
      list-style: none;
  }

  .nav__links {
      color: #fff;
      text-decoration: none;
  }

  .nav__menu {
      margin-left: auto;
      cursor: pointer;
      display: none;
  }

  .nav__img {
      display: block;
      width: 30px;
  }

  .nav__close {
      display: var(--show, none);
  }

  /* ------------------------ */
  /* Contenedor  */
  /* ------------------------ */
  /* Estilos para el contenedor con texto y llamada a la acción */

  .hero__container {
      max-width: 800px;
      --padding-container: 0;
      /* Ajuste del margen interno del contenedor hero */
      display: grid;
      grid-auto-rows: max-content;
      align-content: center;
      gap: 1em;
      padding-bottom: 100px;
      text-align: center;
  }

  .hero__title {
      font-size: 3rem;
      /* Tamaño del título en el hero */
  }

  .hero__paragraph {
      margin-bottom: 20px;
  }

  .cta {
      display: inline-block;
      background-color: #0facbf;
      justify-self: center;
      color: #fff;
      text-decoration: none;
      padding: 13px 30px;
      border-radius: 16px;
  }

  /* About */

  .about {
      text-align: center;
  }

  .subtitle {
      color: var(--color-title);
      font-size: 2rem;
      /* Tamaño de la subtitulo en la sección About */
      margin-bottom: 25px;
  }

  .about__paragraph {
      line-height: 1.7;
  }

  .about__main {
      padding-top: 80px;
      display: grid;
      width: 90%;
      margin: 0 auto;
      gap: 1em;
      overflow: hidden;
      grid-template-columns: repeat(auto-fit, minmax(260px, auto));
  }

  .about__icons {
      display: grid;
      gap: 1em;
      justify-items: center;
      width: 260px;
      overflow: hidden;
      margin: 0 auto;
  }

  .about__icon {
      width: 40px;
  }

  /* Knowledge */

  .knowledge {
      background-color: #e5e5f7;
      background-image: radial-gradient(#0facbf 0.5px, transparent 0.5px), radial-gradient(#0facbf 0.5px, #e5e5f7 0.5px);
      background-size: 20px 20px;
      background-position: 0 0, 10px 10px;
      overflow: hidden;
  }

  .knowledge__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1em;
      align-items: center;
  }

  .knowledge__picture {
      max-width: 500px;
  }

  .knowledge__paragraph {
      line-height: 1.7;
      margin-bottom: 15px;
  }

  .knowledge__img {
      width: 100%;
      display: block;
  }

  /* price */

  .price {
      text-align: center;
  }

  .price__table {
      padding-top: 60px;
      display: flex;
      flex-wrap: wrap;
      gap: 2.5em;
      justify-content: space-evenly;
      align-items: center;
  }

  .price__element {
      background-color: #e5e5f7;
      text-align: center;
      border-radius: 10px;
      width: 330px;
      padding: 40px;
      --color-plan: #696871;
      --color-price: #1D293F;
      --bg-cta: #fff;
      --color-cta: #0facbf;
      --color-items: #696871;
  }

  .price__element--best {
      width: 370px;
      padding: 60px 40px;
      background-color: #0facbf;
      --color-plan: rgb(255 255 255 / 75%);
      --color-price: #fff;
      --bg-cta: #0a2041;
      --color-cta: #FFF;
      --color-items: #fff;
  }

  .price__name {
      color: var(--color-plan);
      margin-bottom: 15px;
      font-weight: 300;
  }

  .price__price {
      font-size: 2.5rem;
      color: var(--color-price);
  }

  .price__items {
      margin-top: 35px;
      display: grid;
      gap: 1em;
      font-weight: 300;
      font-size: 1.2rem;
      margin-bottom: 50px;
      color: var(--color-items);
  }

  .price__cta {
      display: block;
      padding: 20px 0;
      border-radius: 10px;
      text-decoration: none;
      background-color: var(--bg-cta);
      font-weight: 600;
      color: var(--color-cta);
      box-shadow: 0 0 1px rgba(0, 0, 0, .5);
  }

  /* Testimony */

  .testimony {
      background-color: #e5e5f7;
  }

  .testimony__container {
      display: grid;
      grid-template-columns: 50px 1fr 50px;
      gap: 1em;
      align-items: center;
  }

  .testimony__body {
      display: grid;
      grid-template-columns: 1fr max-content;
      justify-content: space-between;
      align-items: center;
      gap: 2em;
      grid-column: 2/3;
      grid-row: 1/2;
      opacity: 0;
      pointer-events: none;
  }

  .testimony__body--show {
      pointer-events: unset;
      opacity: 1;
      transition: opacity 1.5s ease-in-out;
  }

  .testimony__img {
      width: 250px;
      height: 250px;
      border-radius: 50%;
      object-fit: cover;
      object-position: 50% 30%;
  }

  .testimony__texts {
      max-width: 700px;
  }

  .testimony__course {
      background-color: #0facbf;
      color: #fff;
      display: inline-block;
      padding: 5px;
  }

  .testimony__arrow {
      width: 90%;
      cursor: pointer;
  }

  /* Questions */

  .questions {
      text-align: center;
  }

  .questions__container {
      display: grid;
      gap: 2em;
      padding-top: 50px;
      padding-bottom: 100px;
  }

  .questions__padding {
      padding: 0;
      transition: padding .3s;
      border: 1px solid #0facbf;
      border-radius: 6px;
      height: 100px;
      overflow: hidden;
  }

  .questions__padding--add {
      height: 200px;
  }

  .questions__answer {
      padding: 0 30px 0;
      overflow: hidden;
  }

  .questions__title {
      text-align: left;
      display: flex;
      font-size: 20px;
      padding: 30px 0 30px;
      cursor: pointer;
      color: var(--color-title);
      justify-content: space-between;
  }

  .questions__arrow {
      border-radius: 50%;
      background-color: var(--color-title);
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      margin-left: 10px;
      transition: transform .3s;
  }

  .questions__arrow--rotate {
      transform: rotate(180deg);
  }

  .questions__show {
      text-align: left;
      transition: height .3s;
  }

  .questions__img {
      display: block;
  }

  .questions__copy {
      width: 60%;
      margin: 0 auto;
      margin-bottom: 30px;
  }

  /* Footer */

  .footer {
      background-color: #1D293F;
  }

  .footer__title {
      font-weight: 300;
      font-size: 2rem;
      margin-bottom: 30px;
  }

  .footer__title,
  .footer__newsletter {
      color: #fff;
  }

  .footer__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #fff;
      padding-bottom: 60px;
  }

  .nav--footer {
      padding-bottom: 20px;
      display: grid;
      gap: 1em;
      grid-auto-flow: row;
      height: 100%;
  }

  .nav__link--footer {
      display: flex;
      margin: 0;
      margin-right: 20px;
      flex-wrap: wrap;
  }

  .footer__inputs {
      margin-top: 10px;
      display: flex;
      overflow: hidden;
  }

  .footer__input {
      background-color: #fff;
      height: 50px;
      display: block;
      padding-left: 10px;
      border-radius: 6px;
      font-size: 1rem;
      outline: none;
      border: none;
      margin-right: 16px;
  }

  .footer__submit {
      margin-left: auto;
      display: inline-block;
      height: 50px;
      padding: 0 20px;
      background-color: #0facbf;
      border: none;
      font-size: 1rem;
      color: #fff;
      border-radius: 6px;
      cursor: pointer;
  }

  .footer__copy {
      --padding-container: 30px 0;
      text-align: center;
      color: #fff;
  }

  .footer__copyright {
      font-weight: 300;
  }

  .footer__icons {
      margin-bottom: 10px;
  }

  .footer__img {
      width: 30px;
  }

  /* Media queries */

  @media (max-width: 800px) {
      .nav__menu {
          display: block;

      }
      .nav__link{
        transition: height 1s linear;
      }
      .nav__link--menu {
          position: none;
          background-color: #000;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          z-index: 100;
          opacity: 0;
          pointer-events: none;
          transition: .7s opacity;
      }

      .nav__link--show {
          display: block;
          position: fixed;
          opacity: 1;
          top: 0;
          flex-direction: column;
          background-color: #000;
          left: 0;
          padding: 30px 0;
          width: 100%;
          pointer-events: unset;
      }

      .nav__close {
          position: absolute;
          top: 30px;
          right: 30px;
          width: 30px;
          cursor: pointer;
      }

      .hero__title {
          font-size: 2.5rem;
          /* Ajuste de tamaño en pantalla pequeña */
      }

      .about__main {
          gap: 2em;
      }

      .about__icons:last-of-type {
          grid-column: 1/-1;
      }

      .knowledge__container {
          grid-template-columns: 1fr;
          grid-template-rows: max-content 1fr;
          gap: 3em;
          text-align: center;
      }

      .knowledge__picture {
          grid-row: 1/2;
          justify-self: center;
      }

      .testimony__container {
          grid-template-columns: 30px 1fr 30px;
      }

      .testimony__body {
          grid-template-columns: 1fr;
          grid-template-rows: max-content max-content;
          gap: 3em;
          justify-items: center;
      }

      .testimony__img {
          width: 200px;
          height: 200px;
      }

      .questions__copy {
          width: 100%;
      }

      .footer__container {
          flex-wrap: wrap;
      }

      .nav--footer {
          width: 100%;
          justify-items: center;
      }

      .nav__link--footer {
          width: 100%;
          justify-content: space-evenly;
          margin: 0;
      }

      .footer__form {
          width: 100%;
          justify-content: space-evenly;
      }

      .footer__input {
          flex: 1;
      }
  }

  @media (max-width: 600px) {
      .hero__title {
          font-size: 2rem;
          /* Ajuste de tamaño en pantallas aún más pequeñas */
      }

      .hero__paragraph {
          font-size: 1rem;
          /* Ajuste de tamaño del párrafo en pantallas pequeñas */
      }

      .subtitle {
          font-size: 1.8rem;
          /* Ajuste de tamaño en la sección About en pantallas pequeñas */
      }

      .price__element {
          width: 90%;
          /* Ajuste del ancho de los elementos de precios en pantallas pequeñas */
      }

      .price__element--best {
          width: 90%;
      }

      .price__price {
          font-size: 2rem;
          /* Ajuste de tamaño del precio en pantallas pequeñas */
      }

      .testimony {
          --padding-container: 60px 0;
          /* Ajuste del espacio en la sección de testimonios en pantallas pequeñas */
      }

      .testimony__container {
          grid-template-columns: 28px 1fr 28px;
          gap: .9em;
      }

      .testimony__arrow {
          width: 100%;
          /* Ajuste de ancho de la flecha en pantallas pequeñas */
      }

      .testimony__course {
          margin-top: 15px;
      }

      .questions__title {
          font-size: 1rem;
          /* Ajuste de tamaño del título en preguntas en pantallas pequeñas */
      }

      .footer__title {
          justify-self: start;
          margin-bottom: 15px;
      }

      .nav--footer {
          padding-bottom: 60px;
      }

      .nav__link--footer {
          justify-content: space-between;
      }

      .footer__inputs {
          flex-wrap: wrap;
      }

      .footer__input {
          flex-basis: 100%;
          margin: 0;
          margin-bottom: 16px;
      }

      .footer__submit {
          margin-right: auto;
          margin-left: 0;
      }
  }

  /* Agrega estilos necesarios para el menú hamburguesa y oculta el menú en pantallas más pequeñas */
  .nav__link {
      list-style: none;
      display: flex;
  }

  .nav__items {
      margin-right: 20px;
  }

  .nav__burger {
      cursor: pointer;
      display: none;
      flex-direction: column;
  }

  .burger__line {
      width: 30px;
      height: 3px;
      background-color: #333;
      margin: 6px 0;
  }

  @media only screen and (max-width: 768px) {
        .nav__link--menu{
            display: none;
            opacity: 0;

        }
      .nav__items {
          margin: 10px 0;
      }

      .nav__burger {

          display: flex;
      }
  }