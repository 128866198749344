.blog__main {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    width: 50%;
}

.blog__section {
    flex-basis: 70%;
}

.section__title {
    font-size: 2em;
    color: #0facbf;
    margin-bottom: 20px;
}

.blog__post {
    width: 100%;
    margin-bottom: 40px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.blog__post img {
    width: 70%;
}

.post__title {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

.post__date {
    color: #777;
    margin-bottom: 10px;
}

.post__excerpt {
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
}

.post__read-more {
    display: inline-block;
    background-color: #0facbf;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.post__read-more:hover {
    background-color: #0c7d9a;
}

.blog__sidebar {
    flex-basis: 28%;
}

.sidebar__title {
    font-size: 1.8em;
    color: #0facbf;
    margin-bottom: 20px;
}

.sidebar__categories,
.sidebar__popular-posts {
    list-style: none;
    padding: 0;
}

.sidebar__categories a,
.sidebar__popular-posts a {
    text-decoration: none;
    color: #333;
    font-size: 1.2em;
    margin-bottom: 10px;
    display: block;
    transition: color 0.3s;
}

.sidebar__categories a:hover,
.sidebar__popular-posts a:hover {
   
   
    color: #0facbf;
}
/* Agrega este código al final de tu archivo de estilos CSS */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}



.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}


@media (max-width: 900px) {
    .blog__main{
        display: flex;
        flex-direction: column;

    }
}