.hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #0000008c 5%, #0000008c 100%), var(--backgroundImage-before);
    background-size: cover;
    background-position: center;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 95%, 0 80%);
    z-index: -1;
    
} 
.contact__main {
    padding: 40px 0;
}

.contact__section {
    margin-bottom: 40px;
}

.section__title {
    font-size: 2.5em;
    color: #0facbf;
    margin-bottom: 10px;
}

.section__paragraph {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 20px;
}

.contact__info {
    display: flex;
    flex-wrap: wrap;
}

.info__item {
    flex: 0 0 50%;
    margin-bottom: 20px;
}

.info__item i {
    font-size: 2em;
    color: #0facbf;
    margin-right: 10px;
}


/* Estilos para la sección del formulario de contacto */
.contact__form {
    max-width: 600px;
    margin: 0 auto; /* Esto centrará el formulario en el contenedor */
    text-align:start;
}

.form {
    display: flex;
    flex-direction: column;
}

.form__label {
    font-size: 1.2em;
    margin-bottom: 8px;
    color: #0facbf;
}

.form__input,
.form__textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form__submit {
    padding: 10px;
    background-color: #1D293F;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
}

.form__submit:hover {
    background-color: #0facbf;
}
