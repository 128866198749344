/* Estilos para las secciones de contenido */
.section__title {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 20px;
}

.menu-bar{
    display: none;
}

.section__paragraph {
    font-size: 1.1em;
    line-height: 1.5;
    margin-bottom: 40px;
}

.team__members {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.team__member {
    flex: 1;
    text-align: center;
}



.member__name {
    font-size: 1.5em;
    margin: 10px 0;
}

.member__position {
    font-size: 1.2em;
    color: #555;
}

/* Estilos para la sección de contenido */
.section__title {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 20px;
    display: flex;
    align-items: center; /* Alinea la imagen verticalmente */
}

.section__title img {
    max-height: 1em; /* Ajusta el tamaño máximo de la imagen */
    margin-left: 10px; /* Agrega un espacio entre el texto y la imagen */
}



/* Estilo para la imagen de perfil */
.profile-image {
    max-width: 100%; /* Ajusta el ancho máximo según el contenedor */
    height: auto; /* Mantiene la proporción de aspecto original */
    border-radius: 50%; /* Hace que la imagen tenga bordes redondeados (cambia según sea necesario) */
    display: block; /* Asegura que la imagen sea un elemento de bloque */
    margin: 0 auto; /* Centra la imagen horizontalmente en su contenedor */
}

.about__section h2{
    display: flex;
    height: min-content;
    height: min-content;
}



/* Estilo para la imagen de perfil */
.profile-image {
    max-width: 100%; /* Ajusta el ancho máximo según el contenedor */
    height: auto; /* Mantiene la proporción de aspecto original */
    border-radius: 50%; /* Hace que la imagen tenga bordes redondeados (cambia según sea necesario) */
    display: block; /* Asegura que la imagen sea un elemento de bloque */
    margin: 0 auto; /* Centra la imagen horizontalmente en su contenedor */
}

/* Media query para pantallas más pequeñas */
@media only screen and (max-width: 768px) {
    .section__title {
        font-size: 2em;
    }

    .section__paragraph {
        font-size: 1em;
    }

    .team__member {
        flex: 0 0 100%; /* Hace que los miembros del equipo ocupen todo el ancho en pantallas más pequeñas */
    }

    .about__section h2{
        display: flex;
        flex-direction: column;
    }

    .menu-bar{
        position: fixed;
      display: flex;
      width: 50px;
      height: 50px;
      background-color: transparent;
      z-index: 10000;
      top: 15px;
      right: 20px;
      border-radius: 50%;
      border: none;
      background-image: url('../images/menu.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    
}

/* Media query para pantallas más pequeñas */
@media only screen and (max-width: 768px) {
    .profile-image {
        max-width: 100%; /* Ajusta el ancho máximo en pantallas más pequeñas */
    }
}
/* Estilo para la imagen de perfil */
.profile-image {
    max-width: 150px; /* Tamaño máximo ajustado según tus preferencias */
    height: auto; /* Mantiene la proporción de aspecto original */
    border-radius: 50%; /* Hace que la imagen tenga bordes redondeados (puedes ajustar según sea necesario) */
    border: 4px solid #fff; /* Borde blanco sólido */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Sombra suave alrededor de la imagen (opcional) */
    display: block; /* Asegura que la imagen sea un elemento de bloque */
    margin: 0 auto 15px; /* Centra la imagen horizontalmente y agrega espacio en la parte inferior */
}

/* Media query para pantallas más pequeñas */
@media only screen and (max-width: 768px) {
    .profile-image {
        max-width: 80px; /* Ajusta el tamaño máximo en pantallas más pequeñas */
    }
}

